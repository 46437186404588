import { defineComponent, computed } from '@vue/composition-api';
import { StoreKey } from '@/symbols';
import { injectStrict } from '@/lib/misc';
import AuthMenu from '@/components/AuthMenu.vue';
export default defineComponent({
    components: {
        AuthMenu
    },
    setup() {
        const store = injectStrict(StoreKey);
        const externalData = (url) => computed(() => {
            const user = store.getters['auth/user'];
            return `${url}?id=${user?.sub}`;
        });
        const items = computed(() => [
            {
                text: 'Inregistrare',
                props: {
                    to: { name: 'register' },
                    color: 'green'
                },
                on: {},
                icon: 'mdi-account-plus',
                visible: store.getters['auth/isGuest']
            },
            {
                text: 'Autentificare',
                props: {
                    to: { name: 'login' },
                    color: 'primary'
                },
                on: {},
                icon: 'mdi-account-key',
                visible: store.getters['auth/isGuest']
            },
            {
                text: 'Portal',
                props: {
                    href: 'https://portal.primariabaltesti.ro',
                    color: 'purple'
                },
                on: {},
                icon: 'home',
                visible: !store.getters['auth/isGuest']
            },
            {
                text: 'Incidente',
                props: {
                    to: { name: 'incidents' },
                    color: 'indigo'
                },
                on: {},
                icon: 'mdi-apps',
                visible: !store.getters['auth/isGuest']
            },
            {
                text: 'Programari',
                props: {
                    to: { name: 'booking' },
                    color: 'success'
                },
                on: {},
                icon: 'mdi-apps',
                visible: !store.getters['auth/isGuest']
            },
            {
                text: 'Petitii',
                props: {
                    to: { name: 'petitions' },
                    color: 'warning'
                },
                on: {},
                icon: 'mdi-apps',
                visible: !store.getters['auth/isGuest']
            },
            {
                text: 'Iesire',
                props: {
                    color: 'red'
                },
                on: {
                    click: () => store.dispatch('auth/logout')
                },
                icon: 'mdi-exit-run',
                visible: !store.getters['auth/isGuest']
            }
        ]);
        const rows = computed(() => items.value.filter((itm) => itm.visible).length / 2);
        const height = computed(() => {
            const vh = rows.value === 1 ? 50 : 100 / rows.value;
            return `calc(${vh}vh - 270px) !important`;
        });
        return {
            rows,
            items,
            height
        };
    }
});
